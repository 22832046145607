@font-face {
    font-family: 'sky_reg';
    src: url(/65d0b56f66acde2679301bdc5c950d8f.woff) format('truetype')
}

@font-face {
    font-family: 'sky_med';
    src: url(/8c82ad5d37da5d4112ff524af8886b99.woff) format('truetype')
}

@font-face {
    font-family: 'sky_bold';
    src: url(/5abb57909fc31d419ab958aef0bc0af5.ttf) format('truetype')
}

@font-face {
    font-family: 'sky_italic';
    src: url(/d41d8cd98f00b204e9800998ecf8427e.ttf) format('truetype');
}

@font-face {
    font-family: 'icomoon';
    src: url(/a7e6014dda36ab2730b1de0a77c3fbcd.eot);
    src: url(/a7e6014dda36ab2730b1de0a77c3fbcd.eot#iefix) format('embedded-opentype'),
    url(/0d36b35b0870813784b57cd0dc60010a.ttf) format('truetype'),
    url(/277fceb77efbfe269724bc7a58db0a7c.woff) format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}
@font-face {
    font-family: ''Roboto', sans-serif';
    src: url(/382b7be627e9515af7e4e8855f7cc023.woff) format('truetype')
}
@font-face {
    font-family: ''Roboto', sans-serif';
    src: url(/4f459ce25d495196af2bb297fa3ddf67.woff) format('truetype')
}
@font-face {
    font-family: 'volte_semiBold';
    src: url(/8f9e54c48eb45e15aef5959ac42f7052.ttf) format('truetype')
}
@font-face {
    font-family: 'volte_bold';
    src: url(/a1ca37f3419b3ac94375a7e37cd29d8b.woff) format('truetype')
}

html {
    scrollbar-color: $purple-100 #33345d;
    scrollbar-width: thin;
    cursor: pointer;
    height: 100%;
}

body {
    margin: 0;
}

.eula-base-container.main-container {
    margin-top: 0;
    padding: 0;
    min-height: 100vh;
    /* background-color: #1d1e30; */
    cursor: default;
    background-color: $backgroundAllPage;
    /* margin-bottom: 5em; */
}

.eula-container {
    color:  $iconColor;
    padding: 15px 10px 50px 10px;
}

.eula-container.form-container .page-heading {
    margin-top: 25px;
}

.eula-container p.privacy-sub-head {
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    margin-bottom: 12px;
}

.eula-container .eula-text {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: normal;
    overflow-y: auto;
    padding-right: 20px;
    box-sizing: border-box;
    overflow-x: hidden;
    list-style-type: none;
}

.eula-container .eula-text p {
    line-height: 1.1;
}

.page-main-heading {
    font-family: 'Roboto', sans-serif;
    font-size: 35px;
    font-weight: normal;
    line-height: 1.3;
    font-style: normal;
    font-stretch: normal;
    color:  $iconColor;
}

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

::-webkit-scrollbar-thumb {
    background: $LanguageIconBelowPart;
    border-radius: 50px;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px $LanguageIconBelowPart;
    border-radius: 5px;
}

.eula-text .first_list li {
    margin: 12px 0 12px 0;
}

ol {
    padding-inline-start: 15px;
}

ol.first_list {
    padding-inline-start: 45px;
}

a {
    color: $pink-100;
}

li.marker-size::marker {
    font-size: 16px;
}

ol {
    list-style-type: none;
    counter-reset: item;
    margin: 0;
    padding: 0;
}

ol > li {
    counter-increment: item;
    margin-bottom: 0.6em;
}
ol > li:before {
    display: table-cell;
    padding-right: 0.6em;
}
li ol > li {
    margin: 0;
}

.imp-tc {
    text-align: center;
}

.eula-list > li:before {
    content: '';
}

.eula-block {
    margin: 12px 0 12px 0;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px),
(min-device-width: 320px) and (max-device-width: 812px) and (orientation: landscape) {

    .form-container {
        padding: 1px 10px 50px 40px;
    }

    @-moz-document url-prefix() {
        .form-container {
            padding: 1px 10px 50px 25px;
        }
        ol.first_list {
            padding-inline-start: 17px;
        }
        ol {
            padding-inline-start: 0;
        }
    }
}

@media only screen and (max-width: 768px) {
    h2.page-main-heading {
        font-size: 30px;
    }
}
