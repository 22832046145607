
@font-face {
    font-family: 'sky_reg';
    src: url(/65d0b56f66acde2679301bdc5c950d8f.woff) format('truetype')
}

@font-face {
    font-family: 'sky_med';
    src: url(/8c82ad5d37da5d4112ff524af8886b99.woff) format('truetype')
}

@font-face {
    font-family: 'sky_bold';
    src: url(/5abb57909fc31d419ab958aef0bc0af5.ttf) format('truetype')
}

@font-face {
    font-family: 'sky_italic';
    src: url(/d41d8cd98f00b204e9800998ecf8427e.ttf) format('truetype');
}

@font-face {
    font-family: 'icomoon';
    src: url(/a7e6014dda36ab2730b1de0a77c3fbcd.eot);
    src: url(/a7e6014dda36ab2730b1de0a77c3fbcd.eot#iefix) format('embedded-opentype'),
    url(/0d36b35b0870813784b57cd0dc60010a.ttf) format('truetype'),
    url(/277fceb77efbfe269724bc7a58db0a7c.woff) format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}
@font-face {
    font-family: ''Roboto', sans-serif';
    src: url(/382b7be627e9515af7e4e8855f7cc023.woff) format('truetype')
}
@font-face {
    font-family: ''Roboto', sans-serif';
    src: url(/4f459ce25d495196af2bb297fa3ddf67.woff) format('truetype')
}
@font-face {
    font-family: 'volte_semiBold';
    src: url(/8f9e54c48eb45e15aef5959ac42f7052.ttf) format('truetype')
}
@font-face {
    font-family: 'volte_bold';
    src: url(/a1ca37f3419b3ac94375a7e37cd29d8b.woff) format('truetype')
}

html {
    /* scrollbar-color: $purple-100 #33345d; */
    scrollbar-width: thin;
    cursor: pointer;
}

body {
    margin: 0;
}

a {
    color: #00A0E3 !important;
}

.privacy-policy.main-container {
    margin-top: 0;
    padding: 0;
    min-height: 100vh;
    /* background-color: $backgroundAllPage; */
    cursor: default;
}

.privacy-policy-container p {
    background-repeat: no-repeat;
    background-size: 20px;
    padding-left: 30px;
    color: white !important;
}

.privacy-policy-container li {
    margin: 10px;
}

.privacy-policy-container {
    /* color:  $iconColor; */
    padding: 10px 10px 50px 10px;
}

.privacy-policy-container.form-container .page-heading {
    margin-top: 25px;
}

.privacy-policy-container p.privacy-sub-head {
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    margin-bottom: 12px;
}

.privacy-policy-container .privacy-policy-text {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: normal;
    overflow-y: auto;
    padding-right: 20px;
    box-sizing: border-box;
}

.privacy-policy-container .privacy-policy-text p {
    font-family: 'Roboto', sans-serif;
    line-height: 1.1;
    color: white !important;
}

.page-main-heading {
    font-family: 'Roboto', sans-serif;
    font-size: 35px;
    font-weight: normal;
    line-height: 1.3;
    font-style: normal;
    font-stretch: normal;
    /* color:  $iconColor; */
}

.sub-heading {
    margin-left: 10px;
}

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

::-webkit-scrollbar-thumb {
    /* background: $LanguageIconBelowPart; */
    border-radius: 50px;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px  #8856df;
    border-radius: 5px;
}
.privacy-policy-container p span{
    text-align: unset!important;
    text-decoration: none!important;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px),
(min-device-width: 320px) and (max-device-width: 812px) and (orientation: landscape),
(max-device-width: 892px) and (orientation: landscape) {
    .form-container {
        padding: 0;
    }
    .privacy-policy-container p{
        padding-right: 30px;
        text-decoration: none!important;
    }
    .privacy-policy-container p span{
        line-height: 0;
        text-decoration: none!important;
    }
    .privacy-policy-containerUL{
        margin-left: 0 !important;
        padding-left: 1.3rem;
    }
}
